import jwtDecode from "jwt-decode";
import { merge } from "lodash";

import { userState } from "lib/recoil/atoms/user.atom";
import { RecoilSet } from "lib/recoil/components/recoil-utils/recoil-utils";
import { getUserFromLS } from "lib/utils/local-storage";
import { queryClient } from "components/providers/react-query.provider";
import { HttpResponse } from "./common/http-client";
import { RpcStatus, V1RefreshTokensResponse } from "./common/data-contracts";
import { DecodedToken } from "lib/hooks/use-sign-in.hook";

export type RequestAfterRefetch = {
    resource: RequestInfo | URL;
    config: RequestInit;
    res: (value: Response | PromiseLike<Response>) => void;
    rej: (reason?: any) => void
};

export const onSuccessRefresh = (
    response: HttpResponse<V1RefreshTokensResponse, RpcStatus>,
) => {
    const user = getUserFromLS();

    if (user?.auth && response.data.access) {
        user.auth.access = response.data.access;

        const decodedToken = jwtDecode<DecodedToken>(response.data.access);
        user.auth.refreshTimestamp = decodedToken.exp;

        RecoilSet(userState, user);
    }
};

export const onErrorRefresh = (error: any) => {
    if (error.status === 400) {
        RecoilSet(userState, null);
        void queryClient.cancelQueries();
        queryClient.clear();
    }
};

export const callSuspendedRequestsAfterRefresh = async (
    requestsAfterRefresh: RequestAfterRefetch[],
    originalFetch: (input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<any>,
) => {
    const updatedUserFromLS = getUserFromLS();
    const newAccessToken = updatedUserFromLS?.auth?.access;

    for (const request of requestsAfterRefresh) {
        try {
            const response = await originalFetch(
                request.resource,
                merge(request.config, {
                    headers: {
                        Authorization: newAccessToken as string,
                    },
                }),
            );
            request.res(response);
        } catch (err) {
            request.rej(err);
        }
    }
};
