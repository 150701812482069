import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { Open_Sans } from 'next/font/google'

import { CompositeProvider } from "components/providers/composite.provider";
import nextI18NextConfig from "../next-i18next.config.js";

// If loading a variable font, you don't need to specify the font weight
const openSans = Open_Sans({ subsets: ['latin'] })

const App = (props: AppProps) => {
    return (
        <main className={openSans.className}>
            <CompositeProvider {...props}>
                <props.Component {...props.pageProps} />
            </CompositeProvider>
        </main>
    );
};

export default appWithTranslation(App, nextI18NextConfig);
