import { useEffect } from "react";
import { zIndexes } from "lib/z-indexes";

export const TranslationModeProvider = () => {
  useEffect(() => {
      const i18nextEditorPopup = document.getElementById("i18next-editor-popup");

      const setZIndex = () => {
        if (i18nextEditorPopup && zIndexes.translationWidget) {
          i18nextEditorPopup.style.zIndex = `${zIndexes.translationWidget}`;
        }
      };

      setZIndex();

      i18nextEditorPopup?.addEventListener("click", setZIndex);
  }, []);

  return null;
};
