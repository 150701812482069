/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    Permissionv1CreateSubRoleRequest,
    Permissionv1CreateSubRoleResponse,
    Permissionv1EmptyResponse,
    RpcStatus,
    V1Attribute,
    V1GrantRevokeAttributeResponse,
    V1GrantRevokePermissionsActionsRequest,
    V1GrantRevokePermissionsActionsResponse,
    V1SetActiveResponse,
    V1SubRole,
    V1UpdateAccessesResponse,
    V1UpdateB2BParentIDResponse,
    V1UserAttribute,
    V1UserAttributes,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Permissions<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceUpdateAccesses
     * @request PATCH:/permissions/access/users/{user_id}
     * @secure
     */
    permissionServiceUpdateAccesses = (
        userId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateAccessesResponse, RpcStatus>({
            path: `/permissions/access/users/${userId}`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceListPermissionActionsGateway
     * @request GET:/permissions/actions
     * @secure
     */
    permissionServiceListPermissionActionsGateway = (
        query?: {
            sub_role_id?: string;
            user_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<string[], RpcStatus>({
            path: `/permissions/actions`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceGrantRevokePermissionsActions
     * @request POST:/permissions/actions
     * @secure
     */
    permissionServiceGrantRevokePermissionsActions = (
        body: V1GrantRevokePermissionsActionsRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionsActionsResponse, RpcStatus>({
            path: `/permissions/actions`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceGetAttributes
     * @request GET:/permissions/attributes
     * @secure
     */
    permissionServiceGetAttributes = (params: RequestParams = {}) =>
        this.request<V1Attribute[], RpcStatus>({
            path: `/permissions/attributes`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceSetActive
     * @request PUT:/permissions/b2b_companies/{id}/active
     * @secure
     */
    permissionServiceSetActive = (
        id: string,
        body: {
            active: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1SetActiveResponse, RpcStatus>({
            path: `/permissions/b2b_companies/${id}/active`,
            method: "PUT",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceUpdateB2BParentId
     * @request PATCH:/permissions/b2b_companies/hierarchy/{id}
     * @secure
     */
    permissionServiceUpdateB2BParentId = (
        id: string,
        body: {
            parent_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateB2BParentIDResponse, RpcStatus>({
            path: `/permissions/b2b_companies/hierarchy/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceListSubRoles
     * @request GET:/permissions/sub-roles
     * @secure
     */
    permissionServiceListSubRoles = (params: RequestParams = {}) =>
        this.request<V1SubRole[], RpcStatus>({
            path: `/permissions/sub-roles`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceCreateSubRole
     * @request POST:/permissions/sub-roles
     * @secure
     */
    permissionServiceCreateSubRole = (
        body: Permissionv1CreateSubRoleRequest,
        params: RequestParams = {},
    ) =>
        this.request<Permissionv1CreateSubRoleResponse, RpcStatus>({
            path: `/permissions/sub-roles`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceGetSubRole
     * @request GET:/permissions/sub-roles/{id}
     * @secure
     */
    permissionServiceGetSubRole = (id: string, params: RequestParams = {}) =>
        this.request<V1SubRole, RpcStatus>({
            path: `/permissions/sub-roles/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceUpdateSubRole
     * @request PATCH:/permissions/sub-roles/{id}
     * @secure
     */
    permissionServiceUpdateSubRole = (
        id: string,
        body: {
            title?: string;
            /** @format int32 */
            avatar_id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<Permissionv1EmptyResponse, RpcStatus>({
            path: `/permissions/sub-roles/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceChangeStatusSubRole
     * @request POST:/permissions/sub-roles/{id}/change-status
     * @secure
     */
    permissionServiceChangeStatusSubRole = (
        id: string,
        body: {
            is_activate?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<Permissionv1EmptyResponse, RpcStatus>({
            path: `/permissions/sub-roles/${id}/change-status`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceCheckUserAttribute
     * @request GET:/permissions/users/{user_id}/attributes
     * @secure
     */
    permissionServiceCheckUserAttribute = (
        userId: string,
        query?: {
            attribute_names?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UserAttribute[], RpcStatus>({
            path: `/permissions/users/${userId}/attributes`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceGrantRevokeAttribute
     * @request PATCH:/permissions/users/{user_id}/attributes
     * @secure
     */
    permissionServiceGrantRevokeAttribute = (
        userId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokeAttributeResponse, RpcStatus>({
            path: `/permissions/users/${userId}/attributes`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceGrantSubRole
     * @request PUT:/permissions/users/{user_id}/sub_role
     * @secure
     */
    permissionServiceGrantSubRole = (
        userId: string,
        body: {
            sub_role_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<Permissionv1EmptyResponse, RpcStatus>({
            path: `/permissions/users/${userId}/sub_role`,
            method: "PUT",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PermissionService
     * @name PermissionServiceListUsersAttributes
     * @request GET:/permissions/users/attributes
     * @secure
     */
    permissionServiceListUsersAttributes = (
        query?: {
            user_ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UserAttributes[], RpcStatus>({
            path: `/permissions/users/attributes`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
}
