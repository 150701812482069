import { colors } from "lib/palette";
import { zIndexes } from "lib/z-indexes";
import { createGlobalStyle, css } from "styled-components";

const globalStylesCss = css`
    html,
    body {
        height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "Open Sans", sans-serif;
    }

    * {
        box-sizing: border-box;
    }

    *::after,
    *::before {
        box-sizing: border-box;
    }

    img,
    picture,
    video,
    canvas,
    svg {
        max-width: 100%;
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        overflow-wrap: break-word;
        hyphens: auto;
        margin: 0;
    }

    button,
    input,
    select,
    label {
        font-family: inherit;
        outline: none;
    }

    label {
        display: block;
    }

    button {
        cursor: pointer;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    hr {
        overflow: visible;
    }

    legend {
        box-sizing: border-box;
        color: inherit;
        white-space: normal;
    }

    .mantine-Select-dropdown {
        margin-top: -7px;
    }

    .tg-dialog {
        font-family: "Open Sans", sans-serif !important;
    }

    .tg-dialog-close-btn svg {
        fill: ${colors.black_6} !important;
    }

    .tg-backdrop {
        z-index: ${zIndexes.onboarding} !important;
    }

    .tg-dialog .tg-dialog-dots > span.tg-dot.tg-dot-active {
        background: ${colors.yellow_1} !important;
    }

    .tg-dialog div.tg-dialog-header {
        padding: 17px 20px 0 !important;
    }

    .tg-dialog .tg-dialog-body {
        font-family: "Open Sans", sans-serif !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        letter-spacing: 0 !important;
        text-align: left !important;
        padding: 10px 20px !important;
    }

    .tg-dialog .tg-dialog-footer {
        padding: 10px 20px 25px !important; 
    }

    .tg-dialog .tg-dialog-footer button.tg-dialog-btn {
        height: 36px !important;
        top: 120px !important;
        left: 25px !important;
        padding: 7px 20px !important;
        border-radius: 4px !important;
        border: 1px solid ${colors.purple_3} !important;
        gap: 10px !important;
        color: ${colors.purple_1} !important;
        background: white !important;
    }

    .tg-dialog .tg-dialog-footer button.tg-dialog-btn:hover {
        color: ${colors.purple_1} !important;
    }

    .tg-dialog .tg-dialog-footer button.tg-dialog-btn.disabled {
        border: none !important;
        color: ${colors.black_2} !important;
        background: ${colors.black_4} !important;
    }

    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
`;
export const StyledGlobal = createGlobalStyle`${globalStylesCss};` as any;
