import { IS_WINDOW_DEFINED } from "lib/constants/is-window-defined";
import { useCallback, useState } from "react";
import { DefaultValue } from "recoil";
import { RecoilSync, RecoilSyncOptions } from "recoil-sync";

type Read = Exclude<RecoilSyncOptions["read"], undefined>;
type Write = Exclude<RecoilSyncOptions["write"], undefined>;
type Listen = Exclude<RecoilSyncOptions["listen"], undefined>;

const DEFAULT_VALUE = new DefaultValue();

export const RecoilSyncStorage = (
    {
        store,
        ...props
    }: Omit<RecoilSyncOptions, "read" | "write" | "listen"> & {
        store?: Storage
    }
) => {
    const [storage] = useState(
        store || (IS_WINDOW_DEFINED ? window.localStorage : null),
    );

    const read = useCallback<Read>(
        (key) => {
            if (!storage) {
                return DEFAULT_VALUE;
            }

            const item = storage.getItem(key);
            if (item === undefined || item === null) {
                return DEFAULT_VALUE;
            }

            try {
                return JSON.parse(item);
            } catch (error) {
                console.warn({ key, item, error }, "JSON.parse failed");
                return DEFAULT_VALUE;
            }
        },
        [storage],
    );

    const write = useCallback<Write>(
        ({ diff }) => {
            if (!storage) {
                return;
            }

            diff.forEach((value, key) => {
                if (value instanceof DefaultValue) {
                    storage.removeItem(key);
                } else {
                    try {
                        storage.setItem(key, JSON.stringify(value));
                    } catch (error) {
                        console.warn(
                            { error, key, value },
                            "storage.setItem failed",
                        );
                    }
                }
            });
        },
        [storage],
    );

    const listen = useCallback<Listen>(
        ({ updateItem }) => {
            if (typeof window === "undefined" || !storage) {
                return;
            }

            const listener = (event: StorageEvent) => {
                if (event.storageArea === storage && event.key !== null) {
                    let parsed: unknown;

                    if (
                        event.newValue === null ||
                        event.newValue === undefined
                    ) {
                        parsed = DEFAULT_VALUE;
                    } else {
                        try {
                            parsed = JSON.parse(event.newValue);
                        } catch (error) {
                            parsed = DEFAULT_VALUE;
                            console.warn({ event }, "parseJSON failed");
                        }
                    }

                    updateItem(event.key, parsed);
                }
            };

            window.addEventListener("storage", listener);
            return () => window.removeEventListener("storage", listener);
        },
        [storage],
    );
    return <RecoilSync {...props} read={read} write={write} listen={listen} />;
};
