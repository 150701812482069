const isBrowser = typeof window !== "undefined";
const isProduction = process.env.NODE_ENV === "production";
const defaultLocale = "en";
const locales = [defaultLocale, "ru"];

if (process.env.NEXT_PUBLIC_LOCALIZATION_ENABLE_CI_MODE === "true") {
    locales.push("cimode");
}

const locizeOptions = {
    use: isBrowser
        ? [require("locize").locizePlugin]
        : [],
    react: {
        bindI18n: "languageChanged editorSaved",
    },
};

/** @type {import('next-i18next').UserConfig} */
module.exports = {
    debug: !isProduction,
    reloadOnPrerender: !isProduction,
    i18n: {
        defaultLocale,
        locales,
    },
    serializeConfig: false,
    ...(process.env.NEXT_PUBLIC_LOCIZE_ENABLE_TRANSLATION_MODE === "true" ? locizeOptions : {}),
};

module.exports.defaultLocale = defaultLocale;
