import { useResetRecoilState } from "recoil";
import { PropsWithChildren, useEffect } from "react";
import { useRouter } from "next/router";

import { paths } from "lib/constants/paths";
import { UserType } from "lib/constants/user-type";
import { useUserData } from "lib/hooks/use-user-data.hook";
import { userState } from "lib/recoil/atoms/user.atom";
import { permissionsState } from "lib/recoil/atoms/permissions.atom";

export const AuthGuardProvider = ({
    children,
    statusCode,
}: PropsWithChildren<{ statusCode?: number }>) => {
    const { asPath, push, query } = useRouter();
    const { auth } = useUserData();
    const resetUserData = useResetRecoilState(userState);
    const resetPermissionsData = useResetRecoilState(permissionsState);

    useEffect(() => {
        if (statusCode === 404) {
            return;
        }
        if (!auth?.access && !asPath.startsWith("/auth/")) {
            void push("/auth/sign-in", {});
            resetUserData();
            resetPermissionsData();
        } else if (
            auth?.access &&
            asPath.startsWith("/auth/") &&
            !asPath.startsWith("/auth/invite/")
        ) {
            if (auth?.user?.role === UserType.B2B_USER) {
                void push({
                    pathname: paths.STARTER_B2B,
                    query: {
                        ...(query.return_to
                            ? { return_to: query.return_to }
                            : {}),
                    },
                });
            } else {
                void push({
                    pathname: paths.USERS,
                    query: {
                        ...(query.return_to
                            ? { return_to: query.return_to }
                            : {}),
                    },
                });
            }
        }
    }, [auth, asPath, push, statusCode, query, resetUserData, resetPermissionsData]);

    return <> {children} </>;
};
