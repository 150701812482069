import { useRouter } from "next/router";
import { useEffect, useLayoutEffect, useState } from "react";

import { paths } from "lib/constants/paths";
import { useUserData } from "lib/hooks/use-user-data.hook";
import { UserType } from "lib/constants/user-type";

const pages = [
    {
        path: paths.HOME,
        availableForRole: [UserType.AGGREGATOR_USER, UserType.B2B_USER],
    },
    {
        path: paths.AUTH_INVITE_CONFIRM_TOKEN,
        availableForRole: [UserType.AGGREGATOR_USER, UserType.B2B_USER],
    },
    {
        path: paths.AUTH_INVITE_PASSWORD_SET_TOKEN,
        availableForRole: [UserType.AGGREGATOR_USER, UserType.B2B_USER],
    },
    {
        path: paths.B2B_COMPANIES,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.B2B_COMPANIES_HIERARCHY,
        availableForRole: [UserType.B2B_USER],
    },
    {
        path: paths.B2B_COMPANIES_HIERARCHY_COMPANY_ID,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.CONNECTIONS,
        availableForRole: [UserType.B2B_USER],
    },
    {
        path: paths.GAMES,
        availableForRole: [UserType.AGGREGATOR_USER, UserType.B2B_USER],
    },
    {
        path: paths.ROLES,
        availableForRole: [
            UserType.AGGREGATOR_USER,
            UserType.B2B_USER,
            UserType.PROVIDER,
        ],
    },
    {
        path: paths.ROLE_PERMISSIONS_ROLE_ID_CREATE,
        availableForRole: [
            UserType.AGGREGATOR_USER,
            UserType.B2B_USER,
            UserType.PROVIDER,
        ],
    },
    {
        path: paths.ROLE_PERMISSIONS_ROLE_ID_EDIT,
        availableForRole: [
            UserType.AGGREGATOR_USER,
            UserType.B2B_USER,
            UserType.PROVIDER,
        ],
    },
    {
        path: paths.USERS,
        availableForRole: [
            UserType.AGGREGATOR_USER,
            UserType.B2B_USER,
            UserType.PROVIDER,
        ],
    },
    {
        path: paths.USER_TEST_PAGE,
        availableForRole: [UserType.AGGREGATOR_USER, UserType.B2B_USER],
    },
    {
        path: paths.USERS_PERMISSIONS,
        availableForRole: [UserType.AGGREGATOR_USER, UserType.B2B_USER],
    },
    {
        path: paths.USER_ACCESS_COMPANIES,
        availableForRole: [UserType.B2B_USER],
    },
    {
        path: paths.HANDBOOKS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.HANDBOOKS_TAGS_FOR_GAMES,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.HANDBOOKS_METRICS_FOR_ANALYTICS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.CREATE_NEW_GAME,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.EDIT_GAME,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.STANDARD_REPORTS_AGG,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.STANDARD_REPORTS_PROVIDER,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.STANDARD_REPORTS_B2B,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.B2B_COMPANY_REPORTS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.PROVIDER_COMPANY_REPORTS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.PROVIDERS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.PLATFORM_MONITORING,
        availableForRole: [UserType.AGGREGATOR_USER, UserType.PROVIDER],
    },
    {
        path: paths.AGG_FOLDERS_FOR_REPORTS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.B2B_FOLDERS_FOR_REPORTS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.PROVIDER_FOLDERS_FOR_REPORTS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.USER_ACCESS_TO_AGGREGATOR_REPORTS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.USER_ACCESS_TO_B2B_REPORTS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.USER_ACCESS_TO_PROVIDER_REPORTS,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.B2B_USER_ACCESS_TO_REPORTS,
        availableForRole: [UserType.B2B_USER],
    },
    {
        path: paths.PROVIDER_USER_ACCESS_TO_REPORTS,
        availableForRole: [UserType.PROVIDER],
    },
    {
        path: paths.AGGREGATOR_COMPANY_REPORTS_IN_FOLDERS_TREE,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.B2B_COMPANY_REPORTS_IN_FOLDERS_TREE,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.PROVIDER_COMPANY_REPORTS_IN_FOLDERS_TREE,
        availableForRole: [UserType.AGGREGATOR_USER],
    },
    {
        path: paths.B2B_ANALYTICS_REPORTS,
        availableForRole: [UserType.B2B_USER],
    },
    {
        path: paths.PROVIDER_ANALYTICS_REPORTS,
        availableForRole: [UserType.PROVIDER],
    },
    {
        path: paths.REPORT,
        availableForRole: [
            UserType.AGGREGATOR_USER,
            UserType.B2B_USER,
            UserType.PROVIDER,
        ],
    },
    {
        path: paths.STARTER_B2B,
        availableForRole: [UserType.B2B_USER],
    },
];

export const RedirectToHome = ({ statusCode }: { statusCode?: number }) => {
    const { push, pathname, query } = useRouter();
    const { auth } = useUserData();

    // redirecting functionality doesn't work correctly without pathname cloning
    const [path, setPath] = useState(paths.USERS);
    useLayoutEffect(() => setPath(pathname), [pathname]);

    useEffect(() => {
        if (statusCode === 404) {
            return;
        }
        if (
            auth?.user?.role &&
            !pages
                .find((item) => item?.path === path)
                ?.availableForRole.includes(auth?.user?.role)
        ) {
            if (auth?.user?.role === UserType.B2B_USER) {
                if (path === paths.AUTH_SIGN_IN) {
                    void push({
                        pathname: paths.STARTER_B2B,
                        query: {
                            ...(query.return_to
                                ? { return_to: query.return_to }
                                : {}),
                        },
                    });
                } else {
                    void push(paths.STARTER_B2B);
                }
            } else {
                if (path === paths.AUTH_SIGN_IN) {
                    void push({
                        pathname: paths.USERS,
                        query: {
                            ...(query.return_to
                                ? { return_to: query.return_to }
                                : {}),
                        },
                    });
                } else {
                    void push(paths.USERS);
                }
            }
        }
    }, [auth?.user?.role, path, push, query.return_to, statusCode]);

    return null;
};
