import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            networkMode: "offlineFirst",
            staleTime: 1000 * 60 * 5,
        },
        mutations: {
            networkMode: "offlineFirst",
        },
    },
});

export const ReactQueryProvider = (
    {
        children
    }: React.PropsWithChildren
) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {process.env.APP_ENV !== "production" && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    );
};
