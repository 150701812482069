/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    B2BCompanyv1Contact,
    RpcStatus,
    V1AddGeneralLogoResponse,
    V1AddSignLogoResponse,
    V1CreateContactsResponse,
    V1CreatePhone,
    V1DeleteContactsResponse,
    V1GetGeneralLogoResponse,
    V1GetSignLogoResponse,
    V1UpdateContactsResponse,
    V1UpdateEmail,
    V1UpdatePhone,
    V1UpdateResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class B2BCompanies<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceListContacts
     * @request GET:/b2b_companies/{b2b_company_id}/contacts
     * @secure
     */
    b2BCompanyServiceListContacts = (
        b2BCompanyId: string,
        params: RequestParams = {},
    ) =>
        this.request<B2BCompanyv1Contact[], RpcStatus>({
            path: `/b2b_companies/${b2BCompanyId}/contacts`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceCreateContacts
     * @request POST:/b2b_companies/{b2b_company_id}/contacts
     * @secure
     */
    b2BCompanyServiceCreateContacts = (
        b2BCompanyId: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}](?: ?[-\p{L}\p{N}]){1,253}[-\p{L}\p{N}]{0,1}$ */
            name?: string;
            phones?: V1CreatePhone[];
            emails?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1CreateContactsResponse, RpcStatus>({
            path: `/b2b_companies/${b2BCompanyId}/contacts`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceDeleteContacts
     * @request DELETE:/b2b_companies/{b2b_company_id}/contacts/{id}
     * @secure
     */
    b2BCompanyServiceDeleteContacts = (
        b2BCompanyId: string,
        id: string,
        params: RequestParams = {},
    ) =>
        this.request<V1DeleteContactsResponse, RpcStatus>({
            path: `/b2b_companies/${b2BCompanyId}/contacts/${id}`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceUpdateContacts
     * @request PATCH:/b2b_companies/{b2b_company_id}/contacts/{id}
     * @secure
     */
    b2BCompanyServiceUpdateContacts = (
        b2BCompanyId: string,
        id: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}](?: ?[-\p{L}\p{N}]){1,253}[-\p{L}\p{N}]{0,1}$ */
            name?: string;
            phones?: V1UpdatePhone[];
            emails?: V1UpdateEmail[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateContactsResponse, RpcStatus>({
            path: `/b2b_companies/${b2BCompanyId}/contacts/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceGetGeneralLogo
     * @request GET:/b2b_companies/{b2b_company_id}/general_logo
     * @secure
     */
    b2BCompanyServiceGetGeneralLogo = (
        b2BCompanyId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetGeneralLogoResponse, RpcStatus>({
            path: `/b2b_companies/${b2BCompanyId}/general_logo`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceAddGeneralLogo
     * @request PUT:/b2b_companies/{b2b_company_id}/general_logo
     * @secure
     */
    b2BCompanyServiceAddGeneralLogo = (
        b2BCompanyId: string,
        body: object,
        params: RequestParams = {},
    ) =>
        this.request<V1AddGeneralLogoResponse, RpcStatus>({
            path: `/b2b_companies/${b2BCompanyId}/general_logo`,
            method: "PUT",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceGetSignLogo
     * @request GET:/b2b_companies/{b2b_company_id}/logo_sign
     * @secure
     */
    b2BCompanyServiceGetSignLogo = (
        b2BCompanyId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetSignLogoResponse, RpcStatus>({
            path: `/b2b_companies/${b2BCompanyId}/logo_sign`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceAddSignLogo
     * @request PUT:/b2b_companies/{b2b_company_id}/logo_sign
     * @secure
     */
    b2BCompanyServiceAddSignLogo = (
        b2BCompanyId: string,
        body: object,
        params: RequestParams = {},
    ) =>
        this.request<V1AddSignLogoResponse, RpcStatus>({
            path: `/b2b_companies/${b2BCompanyId}/logo_sign`,
            method: "PUT",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name B2BCompanyServiceUpdate
     * @request PATCH:/b2b_companies/{id}
     * @secure
     */
    b2BCompanyServiceUpdate = (
        id: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}](?: ?[-\p{L}\p{N}]){1,253}[-\p{L}\p{N}]{0,1}$ */
            title?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateResponse, RpcStatus>({
            path: `/b2b_companies/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
