
import { getUserFromLS } from "lib/utils/local-storage";
import { AuthService } from "./api";
import { IS_WINDOW_DEFINED } from "lib/constants/is-window-defined";
import { RequestAfterRefetch, callSuspendedRequestsAfterRefresh, onErrorRefresh, onSuccessRefresh } from "./interceptor.utilit";

const refreshTokensPath = "/api/v1/auth/refresh-tokens";

const skipAuthorizationPattern = [
    refreshTokensPath,
    "/api/v1/auth/logout",
    "/auth/password",
    "_next",
];

export const fetchInterceptor = () => {
    if (IS_WINDOW_DEFINED) {
        const { fetch: originalFetch } = window;
        let isTokenRefreshing = false;

        const requestsAfterRefresh: RequestAfterRefetch[] = [];

        window.fetch = async (...args) => {
            const [resource, config = {}] = args;

            const userFromLS = getUserFromLS();
            const userRefreshTimestamp = userFromLS?.auth?.refreshTimestamp;
            const accessToken = userFromLS && userFromLS.auth?.access;
            const skipToken = skipAuthorizationPattern.some((value) =>
                new RegExp(value).test(resource.toString()),
            );

            if (!skipToken && accessToken) {
                config.headers = {
                    ...config.headers,
                    Authorization: accessToken,
                };
            }

            const isTokenExpired =
                userRefreshTimestamp &&
                Date.now() / 1000 >= userRefreshTimestamp;

            // eslint-disable-next-line
            return new Promise(async (res, rej) => {
                try {
                    if (!isTokenRefreshing && !skipToken && isTokenExpired) {
                        isTokenRefreshing = true;

                        requestsAfterRefresh.push({
                            resource,
                            config,
                            res,
                            rej,
                        });

                        await AuthService.authServiceRefreshTokens({})
                            .then(onSuccessRefresh)
                            .catch(onErrorRefresh)
                            .finally(() => {
                                isTokenRefreshing = false;
                            });

                        setTimeout(async () => {
                            await callSuspendedRequestsAfterRefresh(
                                requestsAfterRefresh,
                                originalFetch,
                            );

                            requestsAfterRefresh.length = 0;
                        }, 0);

                        return;
                    }

                    if (isTokenRefreshing && resource !== refreshTokensPath) {
                        requestsAfterRefresh.push({
                            resource,
                            config,
                            res,
                            rej,
                        });
                    } else {
                        const response = await originalFetch(resource, config);
                        res(response);
                    }
                } catch (error) {
                    rej(error);
                }
            });
        };
    }
};
