import { IS_ENABLED_TRANSLATION_MODE } from "./constants/is-enabled-translation-mode";

export const zIndexes = {
    ...(IS_ENABLED_TRANSLATION_MODE ? { translationWidget: 2400 } : {}),
    onboarding: 2300,
    pageInfoDrawer: 2250,
    draggingItem: 2200,
    gameDemoModal: 2100,
    drawer: 2000,
    goToConnectionsWarningModal: 1300,
    portalDropdownInModal: 1300,
    warningModal: 1300,
    modal: 1200,
    rightModal: 1100,
    leftMenu: 1000,
    leftMenuOverlay: 400,
    tooltip: 350,
    header: 300,
    mobileHeader: 100,
    mobileModal: 90,
    coverDndRow: 80,
    loadingLayer: 10,
    rightBlock: 6,
    loaderLayer: 6,
    checkboxIcon: 5,
    footer: 5,
    searchInputClearIcon: 3,
    shadow: 3,
    elementWithShadow: 3,
    modalCloseButton: 2,
    authFormIcon: 2,
    searchInputIcon: 1,
    apiKeyLabel: 1,
    menuButton: 1,
    hidElement: -1,
};
