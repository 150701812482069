import { useSetRecoilState } from "recoil";

import { useGetUserPermissionsQuery } from "lib/api/hooks/use-get-user-permissions-query.hook";
import { permissionsState } from "lib/recoil/atoms/permissions.atom";
import { getUserFromLS } from "lib/utils/local-storage";

export const UserPermissions = () => {
    const setPermissionsData = useSetRecoilState(permissionsState);
    const userFromLS = getUserFromLS();

    useGetUserPermissionsQuery(
        userFromLS?.auth?.user.id as string,
        {
            staleTime: 5 * 60 * 1000,
            onSuccess: (res) => {
                setPermissionsData(res);
            },
            enabled: !!userFromLS?.auth?.access && !!userFromLS?.auth?.user.id,
        },
    );

    return null;
};
