/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    RpcStatus,
    UiBackendv1CreateB2BCompanyRequest,
    UiBackendv1CreateB2BCompanyResponse,
    UiBackendv1GetConnectionResponse,
    UiBackendv1GetUserResponse,
    UiBackendv1ListB2BChildrenCompaniesPaginatedForAggB2BUserResponse,
    UiBackendv1ListB2BCompaniesResponse,
    UiBackendv1ListB2BDirectCompaniesPaginatedForB2BUserResponse,
    UiBackendv1ListConnectionsResponse,
    UiBackendv1VerifyOTPRequest,
    UiBackendv1VerifyOTPResponse,
    V1B2BCompanyAPIKey,
    V1CombinedCompanyItem,
    V1CombinedUserItem,
    V1CreateUserRequest,
    V1CreateUserResponse,
    V1DeleteNotConfirmedUsersResponse,
    V1GetCompanyTreeResponse,
    V1GetReportsCountResponse,
    V1ListB2BCompaniesPaginatedByRootIDSResponse,
    V1ListB2BCompaniesWithGameItem,
    V1ShortB2BItem,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Aggregate<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags UIBackendService
     * @name UiBackendServiceGetReportsCount
     * @request GET:/aggregate/all-reports-count
     * @secure
     */
    uiBackendServiceGetReportsCount = (
        query: {
            /** @format int64 */
            page_size?: number;
            /** @format int64 */
            page_number?: number;
            title?: string;
            company_type: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GetReportsCountResponse, RpcStatus>({
            path: `/aggregate/all-reports-count`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name UiBackendServiceCreateUser
     * @request POST:/aggregate/auth/create
     * @secure
     */
    uiBackendServiceCreateUser = (
        body: V1CreateUserRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1CreateUserResponse, RpcStatus>({
            path: `/aggregate/auth/create`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name UiBackendServiceVerifyOtp
     * @request POST:/aggregate/auth/otp
     * @secure
     */
    uiBackendServiceVerifyOtp = (
        body: UiBackendv1VerifyOTPRequest,
        params: RequestParams = {},
    ) =>
        this.request<UiBackendv1VerifyOTPResponse, RpcStatus>({
            path: `/aggregate/auth/otp`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name UiBackendServiceDeleteNotConfirmedUsers
     * @request DELETE:/aggregate/auth/users/{user_id}
     * @secure
     */
    uiBackendServiceDeleteNotConfirmedUsers = (
        userId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1DeleteNotConfirmedUsersResponse, RpcStatus>({
            path: `/aggregate/auth/users/${userId}`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionService
     * @name UiBackendServiceGetB2BCompanyApiKey
     * @request GET:/aggregate/b2b/{b2b_company_id}/api-keys
     * @secure
     */
    uiBackendServiceGetB2BCompanyApiKey = (
        b2BCompanyId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1B2BCompanyAPIKey, RpcStatus>({
            path: `/aggregate/b2b/${b2BCompanyId}/api-keys`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UIBackendService
     * @name UiBackendServiceListB2BChildrenCompaniesPaginatedForAggB2BUser
     * @request GET:/aggregate/b2b_children_companies/{parent_id}
     * @secure
     */
    uiBackendServiceListB2BChildrenCompaniesPaginatedForAggB2BUser = (
        parentId: string,
        query?: {
            /** @format int64 */
            page_size?: number;
            /** @format int64 */
            page_number?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<
            UiBackendv1ListB2BChildrenCompaniesPaginatedForAggB2BUserResponse,
            RpcStatus
        >({
            path: `/aggregate/b2b_children_companies/${parentId}`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name UiBackendServiceListB2BCompanies
     * @request GET:/aggregate/b2b_companies
     * @secure
     */
    uiBackendServiceListB2BCompanies = (
        query?: {
            with_sub_children?: boolean;
            b2b_company_type?: string;
            b2b_company_ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<UiBackendv1ListB2BCompaniesResponse, RpcStatus>({
            path: `/aggregate/b2b_companies`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name UiBackendServiceCreateB2BCompany
     * @request POST:/aggregate/b2b_companies
     * @secure
     */
    uiBackendServiceCreateB2BCompany = (
        body: UiBackendv1CreateB2BCompanyRequest,
        params: RequestParams = {},
    ) =>
        this.request<UiBackendv1CreateB2BCompanyResponse, RpcStatus>({
            path: `/aggregate/b2b_companies`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name UiBackendServiceGetB2BCompany
     * @request GET:/aggregate/b2b_companies/{id}
     * @secure
     */
    uiBackendServiceGetB2BCompany = (id: string, params: RequestParams = {}) =>
        this.request<V1CombinedCompanyItem, RpcStatus>({
            path: `/aggregate/b2b_companies/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UIBackendService
     * @name UiBackendServiceListB2BCompaniesWithGame
     * @request GET:/aggregate/b2b_companies/with-game/{game_id}
     * @secure
     */
    uiBackendServiceListB2BCompaniesWithGame = (
        gameId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1ListB2BCompaniesWithGameItem[], RpcStatus>({
            path: `/aggregate/b2b_companies/with-game/${gameId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags B2BCompanyService
     * @name UiBackendServiceGetCompanyTree
     * @request GET:/aggregate/b2b_companies/with-user-access/{user_id}
     * @secure
     */
    uiBackendServiceGetCompanyTree = (
        userId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetCompanyTreeResponse, RpcStatus>({
            path: `/aggregate/b2b_companies/with-user-access/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UIBackendService
     * @name UiBackendServiceListB2BCompaniesPaginatedByRootIds
     * @request GET:/aggregate/b2b_root_companies
     * @secure
     */
    uiBackendServiceListB2BCompaniesPaginatedByRootIds = (
        query?: {
            /** @format int64 */
            page_size?: number;
            /** @format int64 */
            page_number?: number;
            root_ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListB2BCompaniesPaginatedByRootIDSResponse, RpcStatus>({
            path: `/aggregate/b2b_root_companies`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UIBackendService
     * @name UiBackendServiceListConnections
     * @request GET:/aggregate/connections
     * @secure
     */
    uiBackendServiceListConnections = (
        query?: {
            is_verified?: boolean;
            is_banned?: boolean;
            vendor_ids?: string[];
            project_ids?: string[];
            /** @format int64 */
            page_size?: number;
            /** @format int64 */
            page_number?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<UiBackendv1ListConnectionsResponse, RpcStatus>({
            path: `/aggregate/connections`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UIBackendService
     * @name UiBackendServiceGetConnection
     * @request GET:/aggregate/connections/{b2b_company_id}/{id}
     * @secure
     */
    uiBackendServiceGetConnection = (
        b2BCompanyId: string,
        id: string,
        params: RequestParams = {},
    ) =>
        this.request<UiBackendv1GetConnectionResponse, RpcStatus>({
            path: `/aggregate/connections/${b2BCompanyId}/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UIBackendService
     * @name UiBackendServiceListB2BDirectCompaniesPaginatedB2BUser
     * @request GET:/aggregate/direct_access_b2b_companies
     * @secure
     */
    uiBackendServiceListB2BDirectCompaniesPaginatedB2BUser = (
        query?: {
            /** @format int64 */
            page_size?: number;
            /** @format int64 */
            page_number?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<
            UiBackendv1ListB2BDirectCompaniesPaginatedForB2BUserResponse,
            RpcStatus
        >({
            path: `/aggregate/direct_access_b2b_companies`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionService
     * @name UiBackendServiceListNoConnectionsB2B
     * @request GET:/aggregate/no-connections/b2b-companies
     * @secure
     */
    uiBackendServiceListNoConnectionsB2B = (
        query?: {
            vendor_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ShortB2BItem[], RpcStatus>({
            path: `/aggregate/no-connections/b2b-companies`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UserService
     * @name UiBackendServiceListUsers
     * @request GET:/aggregate/users
     * @secure
     */
    uiBackendServiceListUsers = (
        query?: {
            name_pattern?: string;
            b2b_company_id?: string;
            vendor_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1CombinedUserItem[], RpcStatus>({
            path: `/aggregate/users`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UserService
     * @name UiBackendServiceGetUser
     * @request GET:/aggregate/users/{user_id}
     * @secure
     */
    uiBackendServiceGetUser = (userId: string, params: RequestParams = {}) =>
        this.request<UiBackendv1GetUserResponse, RpcStatus>({
            path: `/aggregate/users/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
}
