/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    Authv1EmptyResponse,
    Integrationv1B2BCompany,
    Integrationv1UpdateVendorResponse,
    Integrationv1Vendor,
    RpcStatus,
    UiBackendv1CreateVendorRequest,
    UiBackendv1CreateVendorResponse,
    V1B2BHandler,
    V1Category,
    V1CategoryCreateRequest,
    V1Class,
    V1Connection,
    V1CreateB2BCompanyAPIKeyResponse,
    V1CreateB2BHandlerRequest,
    V1CreateB2BHandlerResponse,
    V1CreateGameRequest,
    V1DeleteGameImageResponse,
    V1Filter,
    V1FullVendor,
    V1Game,
    V1GameAgg,
    V1GetB2BGameBlackListResponse,
    V1ListAvailableVendorsForB2BResponse,
    V1ListGamesResponse,
    V1ListNoConnectionVendorsResponseVendor,
    V1SetConnectionBannedResponse,
    V1SetGameImageResponse,
    V1SetGameListConnectionResponse,
    V1SetVendorBannedResponse,
    V1StartDemoRequest,
    V1StartDemoResponse,
    V1TagCreateRequest,
    V1TagCreateResponse,
    V1TagListResponse,
    V1TagUpdateResponse,
    V1UpdateB2BAccessToVendorResponse,
    V1UpdateB2BCompanyAPIKeyResponse,
    V1UpdateB2BGameBLRequest,
    V1UpdateB2BGameBLResponse,
    V1UpdateB2BGamesResponse,
    V1UpdateB2BHandlerResponse,
    V1UpdateGameResponse,
    V1UpdateGameTagsRelationResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Integration<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceListAvailableVendorsForB2B
     * @request GET:/integration/b2b/{b2b_company_id}/access/vendors
     * @secure
     */
    integrationServiceListAvailableVendorsForB2B = (
        b2BCompanyId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1ListAvailableVendorsForB2BResponse, RpcStatus>({
            path: `/integration/b2b/${b2BCompanyId}/access/vendors`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateB2BAccessToVendor
     * @request PATCH:/integration/b2b/{b2b_company_id}/access/vendors
     * @secure
     */
    integrationServiceUpdateB2BAccessToVendor = (
        b2BCompanyId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateB2BAccessToVendorResponse, RpcStatus>({
            path: `/integration/b2b/${b2BCompanyId}/access/vendors`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceCreateB2BCompanyApiKey
     * @request POST:/integration/b2b/{b2b_company_id}/api-keys
     * @secure
     */
    integrationServiceCreateB2BCompanyApiKey = (
        b2BCompanyId: string,
        body: {
            /** @format date-time */
            expire_at?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1CreateB2BCompanyAPIKeyResponse, RpcStatus>({
            path: `/integration/b2b/${b2BCompanyId}/api-keys`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags TransactionService
     * @name IntegrationServiceUpdateB2BCompanyApiKey
     * @request PATCH:/integration/b2b/{b2b_company_id}/api-keys
     * @secure
     */
    integrationServiceUpdateB2BCompanyApiKey = (
        b2BCompanyId: string,
        body: {
            /** @format date-time */
            expire_at?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateB2BCompanyAPIKeyResponse, RpcStatus>({
            path: `/integration/b2b/${b2BCompanyId}/api-keys`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateB2BGames
     * @request PATCH:/integration/b2b/{b2b_company_id}/games
     * @secure
     */
    integrationServiceUpdateB2BGames = (
        b2BCompanyId: string,
        body: {
            include?: string[];
            exclude?: string[];
            action?: "create" | "delete";
            filter?: V1Filter;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateB2BGamesResponse, RpcStatus>({
            path: `/integration/b2b/${b2BCompanyId}/games`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceGetB2BUrlsMeta
     * @summary B2B
     * @request GET:/integration/b2b/{handler_name}/urls-meta
     */
    integrationServiceGetB2BUrlsMeta = (
        handlerName: string,
        params: RequestParams = {},
    ) =>
        this.request<Authv1EmptyResponse, RpcStatus>({
            path: `/integration/b2b/${handlerName}/urls-meta`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateB2BCompany
     * @request PATCH:/integration/b2b/{id}
     * @secure
     */
    integrationServiceUpdateB2BCompany = (
        id: string,
        body: {
            urls?: object;
            handler_name?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<Integrationv1B2BCompany, RpcStatus>({
            path: `/integration/b2b/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateB2BGameBl
     * @request PATCH:/integration/b2b/games/black-list
     * @secure
     */
    integrationServiceUpdateB2BGameBl = (
        body: V1UpdateB2BGameBLRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateB2BGameBLResponse, RpcStatus>({
            path: `/integration/b2b/games/black-list`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags UIBackendService
     * @name UiBackendServiceGetB2BGameBlackList
     * @request GET:/integration/b2b/games/black-list/{action}
     * @secure
     */
    uiBackendServiceGetB2BGameBlackList = (
        action: string,
        query?: {
            vendor_ids?: string[];
            game_ids?: string[];
            b2b_root_ids?: string[];
            /**
             * @format int32
             * @default "1"
             */
            page_number?: number;
            /**
             * @format int32
             * @default "50"
             */
            page_size?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GetB2BGameBlackListResponse, RpcStatus>({
            path: `/integration/b2b/games/black-list/${action}`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceB2BHandlerNamesList
     * @request GET:/integration/b2b/handler-names
     * @secure
     */
    integrationServiceB2BHandlerNamesList = (params: RequestParams = {}) =>
        this.request<string[], RpcStatus>({
            path: `/integration/b2b/handler-names`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceListB2BHandler
     * @request GET:/integration/b2b_handlers
     * @secure
     */
    integrationServiceListB2BHandler = (params: RequestParams = {}) =>
        this.request<V1B2BHandler[], RpcStatus>({
            path: `/integration/b2b_handlers`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceCreateB2BHandler
     * @summary B2BHandler
     * @request POST:/integration/b2b_handlers
     * @secure
     */
    integrationServiceCreateB2BHandler = (
        body: V1CreateB2BHandlerRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1CreateB2BHandlerResponse, RpcStatus>({
            path: `/integration/b2b_handlers`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceGetB2BHandler
     * @request GET:/integration/b2b_handlers/{b2b_handler_name}
     * @secure
     */
    integrationServiceGetB2BHandler = (
        b2BHandlerName: string,
        params: RequestParams = {},
    ) =>
        this.request<V1B2BHandler, RpcStatus>({
            path: `/integration/b2b_handlers/${b2BHandlerName}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateB2BHandler
     * @request PATCH:/integration/b2b_handlers/{b2b_handler_name}
     * @secure
     */
    integrationServiceUpdateB2BHandler = (
        b2BHandlerName: string,
        body: {
            b2b_handler_title?: string;
            config?: object;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateB2BHandlerResponse, RpcStatus>({
            path: `/integration/b2b_handlers/${b2BHandlerName}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateConnection
     * @request PATCH:/integration/connections/{b2b_company_id}/{id}
     * @secure
     */
    integrationServiceUpdateConnection = (
        b2BCompanyId: string,
        id: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}](?: ?[-\p{L}\p{N}]){0,253}[-\p{L}\p{N}]{0,1}$ */
            title?: string;
            config?: object;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Connection, RpcStatus>({
            path: `/integration/connections/${b2BCompanyId}/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceSetConnectionBanned
     * @request PUT:/integration/connections/{b2b_company_id}/{id}/banned
     * @secure
     */
    integrationServiceSetConnectionBanned = (
        b2BCompanyId: string,
        id: string,
        body: {
            is_banned: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1SetConnectionBannedResponse, RpcStatus>({
            path: `/integration/connections/${b2BCompanyId}/${id}/banned`,
            method: "PUT",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceSetGameListConnection
     * @request PUT:/integration/connections/{b2b_company_id}/{id}/for-game-list
     * @secure
     */
    integrationServiceSetGameListConnection = (
        b2BCompanyId: string,
        id: string,
        body: {
            is_for_game_list: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1SetGameListConnectionResponse, RpcStatus>({
            path: `/integration/connections/${b2BCompanyId}/${id}/for-game-list`,
            method: "PUT",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceCreateConnection
     * @request POST:/integration/connections/{b2b_company_id}/{vendor_id}
     * @secure
     */
    integrationServiceCreateConnection = (
        b2BCompanyId: string,
        vendorId: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}](?: ?[-\p{L}\p{N}]){0,253}[-\p{L}\p{N}]{0,1}$ */
            title: string;
            config: object;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Connection, RpcStatus>({
            path: `/integration/connections/${b2BCompanyId}/${vendorId}`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceGetConnectionConfigMeta
     * @request GET:/integration/connections/config/{vendor_id}
     * @secure
     */
    integrationServiceGetConnectionConfigMeta = (
        vendorId: string,
        params: RequestParams = {},
    ) =>
        this.request<Authv1EmptyResponse, RpcStatus>({
            path: `/integration/connections/config/${vendorId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceStartDemo
     * @request POST:/integration/demo
     * @secure
     */
    integrationServiceStartDemo = (
        body: V1StartDemoRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1StartDemoResponse, RpcStatus>({
            path: `/integration/demo`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceListGames
     * @summary Game/categories/classes/tags
     * @request GET:/integration/games
     * @secure
     */
    integrationServiceListGames = (
        query?: {
            ids?: string[];
            title_prefix?: string;
            category_ids?: string[];
            class_id?: string;
            tag_ids?: string[];
            vendor_ids?: string[];
            is_deleted?: boolean;
            is_demo?: boolean;
            /** @format date-time */
            created_at_min?: string;
            /** @format date-time */
            created_at_max?: string;
            /** @format date-time */
            updated_at_min?: string;
            /** @format date-time */
            updated_at_max?: string;
            /**
             * @format int32
             * @default "1"
             */
            page_number?: number;
            /**
             * @format int32
             * @default "50"
             */
            page_size?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListGamesResponse, RpcStatus>({
            path: `/integration/games`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceCreateGame
     * @request POST:/integration/games
     * @secure
     */
    integrationServiceCreateGame = (
        body: V1CreateGameRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1Game, RpcStatus>({
            path: `/integration/games`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateGameTagsRelation
     * @request PATCH:/integration/games/{game_id}/tags
     * @secure
     */
    integrationServiceUpdateGameTagsRelation = (
        gameId: string,
        body: {
            tag_names?: string[];
            tag_ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateGameTagsRelationResponse, RpcStatus>({
            path: `/integration/games/${gameId}/tags`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceGetGame
     * @request GET:/integration/games/{id}
     * @secure
     */
    integrationServiceGetGame = (id: string, params: RequestParams = {}) =>
        this.request<V1GameAgg, RpcStatus>({
            path: `/integration/games/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateGame
     * @request PATCH:/integration/games/{id}
     * @secure
     */
    integrationServiceUpdateGame = (
        id: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}.,:'&!()_\\"#*+;=](?: ?[-\p{L}\p{N}].,:'&!()_\\"#*+;=){0,253}[-\p{L}\p{N}.,:'&!()_\\"#*+;=]{0,1}$ */
            title?: string;
            is_demo_mode?: boolean;
            is_deleted?: boolean;
            category_id?: string;
            has_freespin?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateGameResponse, RpcStatus>({
            path: `/integration/games/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceDeleteGameImage
     * @request DELETE:/integration/games/{id}/image/{image_type}
     * @secure
     */
    integrationServiceDeleteGameImage = (
        id: string,
        imageType: string,
        params: RequestParams = {},
    ) =>
        this.request<V1DeleteGameImageResponse, RpcStatus>({
            path: `/integration/games/${id}/image/${imageType}`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceSetGameImage
     * @request PUT:/integration/games/{id}/image/{image_type}
     * @secure
     */
    integrationServiceSetGameImage = (
        id: string,
        imageType: string,
        body: object,
        params: RequestParams = {},
    ) =>
        this.request<V1SetGameImageResponse, RpcStatus>({
            path: `/integration/games/${id}/image/${imageType}`,
            method: "PUT",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceCategoryList
     * @request GET:/integration/games/categories
     * @secure
     */
    integrationServiceCategoryList = (
        query?: {
            classes?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Category[], RpcStatus>({
            path: `/integration/games/categories`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceCategoryCreate
     * @request POST:/integration/games/categories
     * @secure
     */
    integrationServiceCategoryCreate = (
        body: V1CategoryCreateRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1Category, RpcStatus>({
            path: `/integration/games/categories`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceClassList
     * @request GET:/integration/games/classes
     * @secure
     */
    integrationServiceClassList = (params: RequestParams = {}) =>
        this.request<V1Class[], RpcStatus>({
            path: `/integration/games/classes`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceTagList
     * @request GET:/integration/games/tags
     * @secure
     */
    integrationServiceTagList = (
        query?: {
            /**
             * @format int32
             * @default "100"
             */
            page_size?: number;
            /**
             * @format int32
             * @default "1"
             */
            page_number?: number;
            /** @pattern ^[-\p{L}\p{N}.,:'&!()_\\"#*+;=](?: ?[-\p{L}\p{N}].,:'&!()_\\"#*+;=){0,253}[-\p{L}\p{N}.,:'&!()_\\"#*+;=]{0,1}$ */
            name_prefix?: string;
            /** @pattern ^[-\p{L}\p{N}.,:'&!()_\\"#*+;=](?: ?[-\p{L}\p{N}].,:'&!()_\\"#*+;=){0,253}[-\p{L}\p{N}.,:'&!()_\\"#*+;=]{0,1}$ */
            name?: string;
            sort_by_name?: string;
            sort_by_popularity?: string;
            ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1TagListResponse, RpcStatus>({
            path: `/integration/games/tags`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceTagCreate
     * @request POST:/integration/games/tags
     * @secure
     */
    integrationServiceTagCreate = (
        body: V1TagCreateRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1TagCreateResponse, RpcStatus>({
            path: `/integration/games/tags`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceTagUpdate
     * @request PUT:/integration/games/tags/{id}
     * @secure
     */
    integrationServiceTagUpdate = (
        id: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}.,:'&!()_\\"#*+;=](?: ?[-\p{L}\p{N}].,:'&!()_\\"#*+;=){0,253}[-\p{L}\p{N}.,:'&!()_\\"#*+;=]{0,1}$ */
            name: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1TagUpdateResponse, RpcStatus>({
            path: `/integration/games/tags/${id}`,
            method: "PUT",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceListNoConnectionVendors
     * @request GET:/integration/no-connections/vendors
     * @secure
     */
    integrationServiceListNoConnectionVendors = (
        query?: {
            b2b_company_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListNoConnectionVendorsResponseVendor[], RpcStatus>({
            path: `/integration/no-connections/vendors`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceGetFullVendor
     * @request GET:/integration/vendor/{vendor_id}
     * @secure
     */
    integrationServiceGetFullVendor = (
        vendorId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1FullVendor, RpcStatus>({
            path: `/integration/vendor/${vendorId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceListVendors
     * @request GET:/integration/vendors
     * @secure
     */
    integrationServiceListVendors = (
        query?: {
            active?: boolean;
            ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<Integrationv1Vendor[], RpcStatus>({
            path: `/integration/vendors`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name UiBackendServiceCreateVendor
     * @request POST:/integration/vendors
     * @secure
     */
    uiBackendServiceCreateVendor = (
        body: UiBackendv1CreateVendorRequest,
        params: RequestParams = {},
    ) =>
        this.request<UiBackendv1CreateVendorResponse, RpcStatus>({
            path: `/integration/vendors`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceUpdateVendor
     * @request PATCH:/integration/vendors/{vendor_id}
     * @secure
     */
    integrationServiceUpdateVendor = (
        vendorId: string,
        body: {
            /** @pattern ^[-\p{L}\p{N}](?: ?[-\p{L}\p{N}]){0,98}[-\p{L}\p{N}]{0,1}$ */
            vendor_title?: string;
            config?: object;
        },
        params: RequestParams = {},
    ) =>
        this.request<Integrationv1UpdateVendorResponse, RpcStatus>({
            path: `/integration/vendors/${vendorId}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceSetVendorBanned
     * @request PUT:/integration/vendors/{vendor_id}/banned
     * @secure
     */
    integrationServiceSetVendorBanned = (
        vendorId: string,
        body: {
            is_banned: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1SetVendorBannedResponse, RpcStatus>({
            path: `/integration/vendors/${vendorId}/banned`,
            method: "PUT",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceGetVendorConfigMeta
     * @request GET:/integration/vendors/{vendor_type}/config
     * @secure
     */
    integrationServiceGetVendorConfigMeta = (
        vendorType: string,
        params: RequestParams = {},
    ) =>
        this.request<Authv1EmptyResponse, RpcStatus>({
            path: `/integration/vendors/${vendorType}/config`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags IntegrationService
     * @name IntegrationServiceListVendorTypes
     * @request GET:/integration/vendors/vendor-types
     * @secure
     */
    integrationServiceListVendorTypes = (params: RequestParams = {}) =>
        this.request<string[], RpcStatus>({
            path: `/integration/vendors/vendor-types`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
}
